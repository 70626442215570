// HomePage.js
import React from 'react';
import HomeHeader from '../components/HomeHeader';
import HomeBody from '../components/HomeBody';
import HomeFooter from '../components/HomeFooter';

const HomePage = () => {
  return (
    <>
      <HomeHeader />
      <HomeBody />
      <HomeFooter />
    </>
  );
}

export default HomePage;